import React from 'react'
import {Link} from 'gatsby'
import BlogSidebar from './BlogSidebar'
import img6 from '../../assets/images/blog/WhatIsOT.png'
import img4 from '../../assets/images/blog/blog-img4.jpg'
import img5 from '../../assets/images/blog/blog-img5.jpg'
import img7 from '../../assets/images/blog/blog-img7.jpg'
import img11 from '../../assets/images/blog/blog-img11.jpg'
import img12 from '../../assets/images/blog/blog-img12.jpg'
import user1 from '../../assets/images/user1.jpg'
import user2 from '../../assets/images/user2.jpg'
import user3 from '../../assets/images/user3.jpg'
import user4 from '../../assets/images/user4.jpg'

const BlogDetailsContent = () => {
    return (
        <section className="blog-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="blog-details-desc">
                            <div className="article-image">
                                <img src={img6} alt="bb" />
                            </div>

                            <div className="article-content">
                                <div className="entry-meta">
                                    <ul>
                                        <li>
                                            <i className='bx bx-group'></i>
                                            <span>Posted By</span>
                                            <Link to="#">
                                                Kylie Bell
                                            </Link>
                                        </li>
                                        <li>
                                            <i className='bx bx-calendar'></i>
                                            <span>Last Updated</span>
                                            <Link to="#">
                                                25/09/2021
                                            </Link>
                                        </li>
                                        <li>
                                            <i className='bx bx-folder-open'></i>
                                            <span>Category</span>
                                            <Link to="/blog">
                                                Occupational Therapy
                                            </Link>
                                        </li>
                                    </ul>
                                </div>

                                <h3>What is Occupational Therapy?</h3>

                                <p>Returning to the activities you need and want to do is an important part of recovering and regaining independence after an injury or illness. Occupational therapy focuses on the activities of daily living such as dressing, grooming, eating, and homemaking.</p>

                                <p>In some situations, you may require adaptive equipment for these skills. Our experienced occupational therapists can help you master the techniques needed to use these devices. With our holistic approach to improving the wellbeing of your life, we can help you find the right approach.</p>

                                <h3>Physical vs. Occupational Therapy: What are the differences?</h3>

                                <p>When trying to understand the differences between Physical Therapy (PT) and occupational therapy (OT), you must first understand that they both fall under the physical rehabilitation umbrella. The main difference between the two is that PT focuses on the recovery and improvement of movements that your body performs while OT focuses on the improvement of activities that you commonly perform throughout the day, referred to as activities of daily living (ADL). Both PT and OT can be incorporated after an injury or illness, but OT more commonly helps people with behavioral, emotional, and/or motor skill disabilities.</p>

                                <blockquote>
                                    <p>Occupational therapy practitioners ask, “What matters to you?” not, “What’s the matter with you?"</p>
                                    <cite>Virginia Stoffel, PhD, OT</cite>
                                </blockquote>

                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <BlogSidebar />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BlogDetailsContent